import request from "@/utils/request";
export function analyze(data) {
    return request({
        url: "/analyze",
        method: "post",
        data
    });
}

export function getToken() {
    return request({
        url: "/getToken",
        method: "post"
    });
}

export function getAppList() {
    return request({
        url: "/getAppList"
    });
}

export function getNews() {
    return request({
        url: "/news"
    });
}
