<template>
    <div class="index">
        <div class="header">
            <div class="main clearfix">
                <div class="logo-warp">
                    <a href="/">短视频去水印解析</a>
                </div>
                <div class="menu-warp hidden-xs-only">
                    <el-menu
                        :default-active="activeIndex"
                        mode="horizontal"
                        background-color="#2F353F"
                        text-color="#fff"
                        active-text-color="#ffd04b"
                    >
                        <el-menu-item index="2"
                            ><a href="https://api.xiaofany.com" target="_blank"
                                >短视频去水印解析接口</a
                            ></el-menu-item
                        >
                    </el-menu>
                </div>
                <div class="m-menu-warp hidden-sm-and-up">
                    <i class="el-icon-more"></i>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="main">
                <el-card class="box-card" shadow="never">
                    <div class="description">
                        <el-alert
                            title="主页批量解析请下载电脑版使用，下载地址："
                            type="error"
                            effect="dark"
                            :closable="false"
                        >
                            <a
                                href="https://www.aliyundrive.com/s/CfahrUcXTTm"
                                target="_blank"
                                >https://www.aliyundrive.com/s/CfahrUcXTTm</a
                            >
                        </el-alert>
                    </div>
                    <div class="description">
                        <el-alert
                            title="本站已支持免费解析下载D音、X瓜、某手、美拍、某tok、小红书、微博、秒拍、小咖秀、晃咖、微视、全民小视频、全民K歌、美拍、哔哩哔哩、梨视频、网易云音乐、趣多拍、陌陌、Instagram、YouTube、Facebook、Twitter、Tumblr、映客、小影等平台的视频，去除后无任何痕迹，主要用于用户收藏自己喜欢视频。"
                            type="success"
                            effect="dark"
                            :closable="false"
                        >
                        </el-alert>
                    </div>
                    <div class="instructions">
                        <h4>使用方法</h4>
                        <p>
                            1、打开短视频APP，点开某个视频，点击右下角分享按钮，在分享弹框中点击复制链接或通过分享到微信QQ等获取分享链接
                        </p>
                        <p>2、将刚才复制的链接粘贴到下面的输入框</p>
                    </div>
                    <div class="form-warp">
                        <el-row :gutter="10">
                            <el-col :sm="18">
                                <el-input
                                    v-model="url"
                                    placeholder="请输入视频地址"
                                    @blur="handleUrlInputBlur"
                                    clearable
                                ></el-input>
                            </el-col>
                            <el-col :sm="6" class="hidden-xs-only">
                                <el-button
                                    type="success"
                                    @click="handleAnalyzeClick"
                                    :loading="analyzeLoading"
                                    >解析</el-button
                                >
                                <el-button type="danger" @click="url = ''"
                                    >清空</el-button
                                >
                            </el-col>
                            <el-col
                                :sm="6"
                                class="hidden-sm-and-up text-center"
                            >
                                <el-button
                                    type="success"
                                    @click="handleAnalyzeClick"
                                    :loading="analyzeLoading"
                                    >解析</el-button
                                >
                                <el-button type="danger" @click="url = ''"
                                    >清空</el-button
                                >
                            </el-col>
                        </el-row>
                        <div class="download-warp" v-show="result">
                            <a
                                target="_blank"
                                download="video.mp4"
                                rel="noreferrer"
                                :href="result.video"
                                ><el-button type="primary"
                                    >下载视频</el-button
                                ></a
                            >
                            <a
                                target="_blank"
                                rel="noreferrer"
                                :href="result.cover"
                                ><el-button type="warning"
                                    >下载封面</el-button
                                ></a
                            >
                        </div>
                    </div>
                    <div class="instructions">
                        <h4>常见疑问</h4>
                        <p>
                            1、Android手机上可以用吗？
                        </p>
                        <el-alert
                            title="可以的，Android手机在常用的Chrome、UC、360、QQ等浏览器上都可以很方便的使用本站。推荐使用Chrome浏览器获得最佳下载体验。"
                            type="success"
                            :closable="false"
                        ></el-alert>
                        <p>
                            2、iOS设备（iPhone、iPad、iPod）上点击下载视频按钮后，跳转到视频页面，并没有直接下载，怎么办？
                        </p>
                        <el-alert
                            title="因Safari及微信内置浏览器均不支持下载文件，所以保存视频需要借助第三方App来完成下载，建议iOS用户在App Store下载免费的Documents 6，然后在Documents的内置浏览器中使用本站，可以完美下载视频，并且Documents支持将下载的视频移到手机相册。"
                            type="success"
                            :closable="false"
                        >
                        </el-alert>
                        <p>
                            3、我在电脑上用的是IE浏览器，点下载视频按钮后，出现跟上面那用iPad的哥们一样的情况，跳转到视频页面，如何下载到本地呢？
                        </p>
                        <el-alert
                            title='电脑上少数浏览器不支持直接下载，但可以在下载视频按钮上点击右键，然后选择"目标另存为"或"链接存储为"来下载视频；或者到跳转后的视频页面，在视频画面上点击右键，然后选择"视频另存为"来下载视频。当然，我们更推荐在电脑上使用如谷歌Chrome浏览器、360浏览器极速模式、QQ浏览器极速模式等现代浏览器来获得最佳上网体验。'
                            type="success"
                            :closable="false"
                        >
                        </el-alert>
                        <p>
                            4、下载后的视频打不开怎么办？
                        </p>
                        <el-alert
                            title="这种情况极少发生，一般是文件后缀问题，把下载后的文件后缀名改为.mp4即可播放。"
                            type="success"
                            :closable="false"
                        >
                        </el-alert>
                        <p>
                            4、不支持下载直播间的视频吗?
                        </p>
                        <el-alert
                            title="是的，正在直播的视频无法下载，意义也不大。"
                            type="success"
                            :closable="false"
                        >
                        </el-alert>
                    </div>
                </el-card>
            </div>
        </div>
        <div class="footer color-info">
            <p>
                <small
                    >视频归相关网站及作者所有，本站不存储任何视频及图片。</small
                >
            </p>
            <p>
                <small
                    >Copyright © 2020<el-divider direction="vertical" /><a
                        href="http://beian.miit.gov.cn"
                        target="_blank"
                        >鲁ICP备17049248号-1</a
                    ></small
                >
            </p>
        </div>
    </div>
</template>

<script>
import { analyze, getAppList, getNews, getToken } from "@/api";
import { mySign } from "@/utils/sign";

export default {
    data() {
        return {
            activeIndex: "1",
            url: "",
            analyzeLoading: false,
            result: "",
            appList: [],
            showAllApp: false,
            newsList: []
        };
    },
    methods: {
        handleAnalyzeClick() {
            if (this.url === "") {
                this.$message.error("请输入视频链接");
                return false;
            }
            this.analyzeLoading = true;
            this.result = "";
            getToken().then(res => {
                let token = res.data.token;

                let t = new Date().getTime();
                let sign = mySign(this.url, t, token);
                analyze({
                    url: this.url,
                    t,
                    sign,
                    token
                })
                    .then(res => {
                        this.result = res.data;
                        this.analyzeLoading = false;
                    })
                    .catch(() => {
                        this.analyzeLoading = false;
                    });
            });
        },
        checkDev() {
            const img = new Image();

            img.__defineGetter__("id", function() {
                window.location.href = "https://www.xiaofany.com";
            });

            console.log(img);
        },
        handleUrlInputBlur() {
            let reg = /https?:\/\/[!=&\w\-.:%@#?,/\\]+/i;
            let result = reg.exec(this.url);
            if (result) {
                this.url = result[0];
            }
        },
        fetchAppList() {
            getAppList().then(res => {
                this.appList = res.data;
            });
        },
        fetchNews() {
            getNews().then(res => {
                this.newsList = res.data;
            });
        }
    }
};
</script>

<style scoped lang="less">
.header {
    background-color: #2f353f;
    height: 60px;
    .logo-warp {
        float: left;
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        font-weight: bold;
        a {
            color: #fff;
        }
    }
    .menu-warp {
        margin-left: 20px;
        float: left;
    }
    .m-menu-warp {
        float: right;
        height: 60px;
        line-height: 60px;
        margin-right: 20px;
        color: #fff;
    }
}
.container {
    margin-top: 20px;
    margin-bottom: 20px;
    .more-app-flag {
        text-align: center;
    }
    .app-list-warp {
        text-align: center;
        .app-item {
            display: inline-block;
            width: 88px;
            padding: 8px 3px;
            text-align: center;
            .app-logo {
                width: 41px;
                height: 41px;
            }
            .app-name {
                font-size: 14px;
            }
        }
    }
    .description {
        padding: 0 20px;
        margin-bottom: 20px;
    }
    .instructions {
        font-size: 14px;
        margin-bottom: 20px;
        p,
        h4 {
            margin-bottom: 5px;
        }
        .el-alert {
            margin-bottom: 10px;
        }
    }
    .form-warp {
        margin-top: 20px;
        margin-bottom: 50px;
        .el-col {
            margin-bottom: 20px;
        }
        .download-warp {
            text-align: center;
            a {
                margin: 0 10px;
            }
        }
    }
    .news-warp {
        margin-top: 20px;
        .news-item {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ebeef5;
        }
    }
}
.footer {
    padding: 40px 0 20px;
    text-align: center;
    a:hover {
        text-decoration: underline;
    }
}
</style>
